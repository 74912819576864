import { useAuth, usePermissions, useServices } from '@/hooks'
import { filterUserAccessQueryParams, PERMISSION } from '@/services'
import { APIError } from '@/types'
import { UndefinedInitialDataOptions, useQueries, UseQueryResult } from '@tanstack/react-query'
import { ConnectedAccount, Uuid } from '@webapps/numeral-ui-core'

function combine(results: UseQueryResult<ConnectedAccount, APIError>[]) {
    const data = results?.reduce<Record<Uuid, ConnectedAccount>>((acc, result) => {
        const { data } = result

        if (!data) {
            return acc
        }

        return {
            ...acc,
            [data.id]: data
        }
    }, Object.create(null))

    return {
        data,
        isLoading: results.some((result) => result.isLoading),
        isError: results.some((result) => result.isError)
    }
}

export function useQueriesBalancesConnectedAccounts(
    ids?: Uuid[],
    options?: Partial<UndefinedInitialDataOptions<ConnectedAccount, APIError>>
) {
    const { connectedAccountsService } = useServices()
    const { isUserLoggedIn, selectedUserAccess } = useAuth()
    const { hasPermission } = usePermissions()
    const isQueryEnabled =
        isUserLoggedIn && hasPermission(PERMISSION.SETTINGS_VIEW_CONNECTED_ACCOUNTS) && options?.enabled
    const paramsWithUserAccess = {
        ...filterUserAccessQueryParams(selectedUserAccess)
    }

    return useQueries<ConnectedAccount[]>({
        ...options,
        queries: Array.isArray(ids)
            ? [...ids].map((id) => ({
                  enabled: isQueryEnabled as boolean,
                  queryKey: [connectedAccountsService.url, { id, ...paramsWithUserAccess }],
                  queryFn({ signal }) {
                      return connectedAccountsService.findById(id, { signal, params: paramsWithUserAccess })
                  }
              }))
            : [],
        combine: combine as any /*@todo Add types */
    }) as unknown as UseQueryResult<Record<Uuid, ConnectedAccount>>
}
