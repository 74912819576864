import { QueryFallback, RestrictedSection, Table, TableHeader, useTableRowNavigation } from '@/components'
import { useNavigationRoutes, useQueryParams } from '@/hooks'
import { useQueryFindAllAccountHolders } from '@/hooks/api/accountHolders'
import { PageHeaderLayout, PageLayout } from '@/layouts'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { PERMISSION } from '@/services'
import { queryDataAggregation } from '@/utils'
import { AccountHolder, AccountHoldersServiceFindAllQueryOptions, ApiObjectTypeSchema } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import {
    ACCOUNT_HOLDER_DOCUMENTATION_LINK,
    ACCOUNT_HOLDER_FILTERS,
    ACCOUNT_HOLDER_TABLE_COLUMN_CUSTOMIZERS,
    ACCOUNT_HOLDER_TABLE_STATIC_STATE
} from './AccountHolders.page.const'

export const AccountHoldersPage: React.FC = () => {
    const intl = useIntl()
    const { relativePaths } = useNavigationRoutes()
    const title = useMemo<string>(() => intl.formatMessage({ id: 'app.counterparties.account_holders.title' }), [intl])
    const { filterBy } = useQueryParams<AccountHoldersServiceFindAllQueryOptions>({
        filterBy: { configuration: { filters: ACCOUNT_HOLDER_FILTERS } }
    })
    const query = useQueryFindAllAccountHolders({ ...filterBy.parsedState })
    const data = useMemo(() => queryDataAggregation(query.data), [query])
    const isDataEmpty = useMemo<boolean>(() => isEmpty(data), [data])

    const onTableRowClick = useTableRowNavigation<AccountHolder>('id', relativePaths.COUNTERPARTIES.DETAILS)

    return (
        <PageLayout>
            <PageHeaderLayout title={title} showBackButton={false} />
            <RestrictedSection permission={PERMISSION.COUNTERPARTIES_VIEW_ACCOUNT_HOLDERS}>
                <TableHeader<AccountHoldersServiceFindAllQueryOptions>
                    objectType={ApiObjectTypeExtendedSchema.enum.account_holder}
                    isLoading={query.isLoading}
                    filterBy={filterBy}
                />
                <QueryFallback
                    objectType={ApiObjectTypeSchema.enum.account_holder}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError}
                    isDataEmpty={isDataEmpty}
                    isFilteringOrSearching={filterBy.isActive}
                    learnMoreLinkUrl={ACCOUNT_HOLDER_DOCUMENTATION_LINK}>
                    <Table<AccountHolder>
                        data={data}
                        onRowClick={onTableRowClick}
                        onScrollToBottom={query.fetchNextPage}
                        isLoading={query.hasNextPage}
                        state={ACCOUNT_HOLDER_TABLE_STATIC_STATE}
                        columnCustomizers={ACCOUNT_HOLDER_TABLE_COLUMN_CUSTOMIZERS}
                    />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
