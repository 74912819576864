import { DetailPageLink } from '@/components/@misc'
import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { isCorporateConnectedAccount } from '@/services'
import {
    ApiObjectTypeSchema,
    ConnectedAccount,
    DirectDebitMandate,
    ExpectedPayment,
    IncomingPayment,
    PaymentOrder
} from '@webapps/numeral-ui-core'

interface CounterpartyRelatedObjectsProps {
    accountKey?: 'originating_account' | 'receiving_account'
    value?: PaymentOrder | IncomingPayment | ExpectedPayment | DirectDebitMandate
    connectedAccount?: ConnectedAccount
}

export const CounterpartyRelatedObjects: React.FC<CounterpartyRelatedObjectsProps> = ({
    accountKey,
    value,
    connectedAccount
}) => {
    const paymentOrder = value as PaymentOrder
    const isCorporateAccount = isCorporateConnectedAccount(connectedAccount)
    const hasReceivingAccount = globalThis.Boolean(paymentOrder?.receiving_account_id)
    const hasOriginatingAccount = globalThis.Boolean(paymentOrder?.originating_account_id)
    const isIncoming = value?.object === ApiObjectTypeSchema.enum.incoming_payment

    if (isCorporateAccount) {
        return (
            <DetailPageLink
                objectId={connectedAccount?.id as string}
                objectType={ApiObjectTypeSchema.enum.connected_account}
            />
        )
    }

    if (hasOriginatingAccount && accountKey === 'originating_account') {
        return (
            <DetailPageLink
                objectId={paymentOrder?.originating_account_id}
                objectType={
                    isIncoming ? ApiObjectTypeSchema.enum.external_account : ApiObjectTypeSchema.enum.internal_account
                }
            />
        )
    }

    if (hasReceivingAccount && accountKey === 'receiving_account') {
        return (
            <DetailPageLink
                objectId={paymentOrder?.receiving_account_id}
                objectType={
                    isIncoming ? ApiObjectTypeSchema.enum.internal_account : ApiObjectTypeSchema.enum.external_account
                }
            />
        )
    }

    return <>{EMPTY_VALUE_PLACEHOLDER}</>
}
