import { FilterInputs, TableStateWithEntity } from '@/components'
import {
    ConnectedAccountsInput,
    DateInput,
    FileCategoryInput,
    FileDirectionInput,
    FileStatusInput
} from '@/components/@inputs'
import { FileFormat, FormattedTextIntl, StatusLabel } from '@/components/@misc'
import { TableCellShortDate, TableCellText, TableColumnCustomizers } from '@/components/Table'
import { COLUMN_WIDTH_RATIO, DOCUMENTATION_BASE_URL, MIN_COLUMN_WIDTH } from '@/constants'
import { FilesServiceFindAllQueryOptions, NumeralFile } from '@webapps/numeral-ui-core'

export const FILES_DOCUMENTATION_LINK = `${DOCUMENTATION_BASE_URL}/file-object`

export const FILES_TABLE_STATIC_STATE: TableStateWithEntity<NumeralFile> = {
    columnOrder: Object.freeze(['created_at', 'filename', 'direction', 'category', 'format', 'status'])
}

export const FILES_TABLE_COLUMN_CUSTOMIZERS: TableColumnCustomizers<NumeralFile> = {
    created_at: {
        minSize: MIN_COLUMN_WIDTH.SHORT_DATE,
        cell(info) {
            return <TableCellShortDate value={info.getValue()} />
        }
    },
    filename: {
        size: COLUMN_WIDTH_RATIO.FILE_NAME,
        cell(info) {
            return <TableCellText value={info.getValue()} />
        }
    },
    direction: {
        size: COLUMN_WIDTH_RATIO.DIRECTION,
        minSize: MIN_COLUMN_WIDTH.DIRECTION,
        cell(info) {
            return (
                <FormattedTextIntl
                    messageId={`api.files.direction.${info.getValue()}`}
                    defaultMessage={info.getValue()}
                />
            )
        }
    },
    category: {
        minSize: MIN_COLUMN_WIDTH.FILE_CATEGORY
    },
    format: {
        minSize: MIN_COLUMN_WIDTH.FILE_FORMAT,
        cell(info) {
            return <FileFormat value={info.getValue()} />
        }
    },
    status: {
        minSize: MIN_COLUMN_WIDTH.STATUS,
        cell(info) {
            return <StatusLabel value={info.getValue()} />
        }
    }
}

export const FILES_FILTERS: FilterInputs<FilesServiceFindAllQueryOptions> = {
    start_date: <DateInput name="start_date" />,
    end_date: <DateInput name="end_date" />,
    direction: <FileDirectionInput name="direction" />,
    category: <FileCategoryInput name="category" />,
    status: <FileStatusInput name="status" />,
    connected_account_id: <ConnectedAccountsInput name="connected_account_id" isPlaceholderDisplayed={true} />
}
