import { EntityDetailRow } from '@/components'
import { ExternalAccountCustomRows } from './Counterparty.page.types'

export const EXTERNAL_ACCOUNT_DETAIL_ROWS: EntityDetailRow<ExternalAccountCustomRows>[] = [
    'related_objects',
    'holder_name',
    'account_number',
    'bank_code',
    'holder_address'
]
