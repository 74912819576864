import { useAuth, usePermissions, useServices } from '@/hooks'
import { filterUserAccessQueryParams, PERMISSION } from '@/services'
import { type UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query'
import { AccountHolder, Uuid } from '@webapps/numeral-ui-core'

export function useQueryFindByIdAccountHolder(
    id?: Uuid,
    options?: Partial<UndefinedInitialDataOptions<AccountHolder, Error>>
) {
    const { accountHoldersService } = useServices()
    const { selectedUserAccess } = useAuth()
    const { hasPermission } = usePermissions()

    const isQueryEnabled = hasPermission(PERMISSION.COUNTERPARTIES_VIEW_ACCOUNT_HOLDERS) && options?.enabled
    const paramsWithUserAccess = {
        ...filterUserAccessQueryParams(selectedUserAccess)
    }

    return useQuery<AccountHolder, Error>({
        ...options,
        enabled: isQueryEnabled,
        queryKey: [accountHoldersService.url, { id, ...paramsWithUserAccess }],
        queryFn({ signal }) {
            return accountHoldersService.findById(id as string, { signal, params: paramsWithUserAccess })
        }
    })
}
