import { QueryFallback, RestrictedSection, Table, TableHeader, useTableRowNavigation } from '@/components'
import { useNavigationRoutes, useQueryFindAllInquiries, useQueryParams } from '@/hooks'
import { PageHeaderLayout, PageLayout } from '@/layouts'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { queryDataAggregation } from '@/utils'
import { InquiriesServiceFindAllQueryOptions, Inquiry } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import {
    INQUIRY_DOCUMENTATION_LINK,
    INQUIRY_FILTERS,
    INQUIRY_TABLE_COLUMN_CUSTOMIZERS,
    INQUIRY_TABLE_STATIC_STATE
} from './Inquiries.page.const'

export const InquiriesPage: React.FC = () => {
    const intl = useIntl()
    const { relativePaths } = useNavigationRoutes()
    const title = useMemo<string>(() => intl.formatMessage({ id: 'app.payments.inquiries.title' }), [intl])
    const { filterBy } = useQueryParams<InquiriesServiceFindAllQueryOptions>({
        filterBy: { configuration: { filters: INQUIRY_FILTERS } }
    })

    const query = useQueryFindAllInquiries(filterBy.parsedState)
    const data = useMemo(() => queryDataAggregation(query.data), [query])
    const isDataEmpty = useMemo<boolean>(() => isEmpty(data), [data])

    const onTableRowClick = useTableRowNavigation<Inquiry>('id', relativePaths.PAYMENTS.DETAILS)

    return (
        <PageLayout>
            <PageHeaderLayout title={title} showBackButton={false} />
            <RestrictedSection feature={ENTITY_FEATURE.INQUIRY} permission={PERMISSION.PAYMENTS_VIEW_INQUIRIES}>
                <TableHeader<InquiriesServiceFindAllQueryOptions>
                    objectType={ApiObjectTypeExtendedSchema.enum.incoming_payment}
                    isLoading={query.isLoading}
                    filterBy={filterBy}
                />
                <QueryFallback
                    objectType={ApiObjectTypeExtendedSchema.enum.inquiry}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError}
                    isDataEmpty={isDataEmpty}
                    learnMoreLinkUrl={INQUIRY_DOCUMENTATION_LINK}>
                    <Table<Inquiry>
                        data={data}
                        onRowClick={onTableRowClick}
                        onScrollToBottom={query.fetchNextPage}
                        isLoading={query.hasNextPage}
                        state={INQUIRY_TABLE_STATIC_STATE}
                        columnCustomizers={INQUIRY_TABLE_COLUMN_CUSTOMIZERS}
                    />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
