import {
    EntityDetail,
    EntityDetailRow,
    EntityDetailRowCustomizers,
    QueryFallback,
    RestrictedSection
} from '@/components'
import { useQueryFindByIdConnectedAccount, useQueryFindByIdPaymentOrder } from '@/hooks'
import { PageLayout } from '@/layouts'
import {
    CounterpartyCustomRows,
    getPaymentCounterpartyDetailRowCustomizers,
    getPaymentCounterpartyDetailRows
} from '@/pages'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

export const PaymentOrderCounterpartyPage: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdPaymentOrder(uuid)
    const queryConnectedAccount = useQueryFindByIdConnectedAccount(query.data?.connected_account_id, {
        enabled: globalThis.Boolean(query.data?.connected_account_id)
    })
    const rowCustomizers = useMemo<EntityDetailRowCustomizers<CounterpartyCustomRows>>(() => {
        return getPaymentCounterpartyDetailRowCustomizers(query.data, queryConnectedAccount.data)
    }, [query, queryConnectedAccount])

    const rows = useMemo<EntityDetailRow<CounterpartyCustomRows>[]>(() => {
        return getPaymentCounterpartyDetailRows(query.data)
    }, [query])

    return (
        <PageLayout className="PaymentOrderCounterparty">
            <RestrictedSection
                feature={ENTITY_FEATURE.PAYMENT_ORDER}
                permission={PERMISSION.PAYMENTS_VIEW_PAYMENT_ORDERS}>
                <QueryFallback
                    objectType={ApiObjectTypeExtendedSchema.enum.payment_order}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError || isEmpty(query?.data)}
                    isDataEmpty={isEmpty(query?.data)}>
                    <EntityDetail<CounterpartyCustomRows>
                        data={query.data}
                        rows={rows}
                        rowCustomizers={rowCustomizers}
                    />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
