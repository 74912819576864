import {
    FilterInputs,
    TableCellAmount,
    TableCellShortDate,
    TableCellText,
    TableColumnCustomizers,
    TableStateWithEntity
} from '@/components'
import {
    AmountInput,
    BooleanInput,
    ConnectedAccountsInput,
    CurrencyInput,
    DateInput,
    PaymentCaptureSourceInput,
    PaymentCaptureTypeInput,
    ReconciliationStatusInput,
    TextInput
} from '@/components/@inputs'
import { ConnectedAccountName, ReconciliationStatusLabel } from '@/components/@misc'
import { COLUMN_WIDTH_RATIO, DOCUMENTATION_BASE_URL, HIGHLIGHTED_CURRENCY_CODES, MIN_COLUMN_WIDTH } from '@/constants'
import { QueryParamQuickFilters } from '@/hooks'
import { prettyPrintCasedWords } from '@/utils'
import {
    PaymentCapture,
    PaymentCaptureServiceFindAllQueryOptions,
    ReconciliationStatusSchema
} from '@webapps/numeral-ui-core'

export const PAYMENT_CAPTURE_DOCUMENTATION_LINK = `${DOCUMENTATION_BASE_URL}/payment-capture-object`

export const PAYMENT_CAPTURE_TABLE_STATIC_STATE: TableStateWithEntity<PaymentCapture> = {
    columnOrder: Object.freeze([
        'created_at',
        'type',
        'amount',
        'connected_account_id',
        'payment_reference',
        'value_date',
        'reconciliation_status'
    ])
}

export const PAYMENT_CAPTURE_TABLE_COLUMN_CUSTOMIZERS: TableColumnCustomizers<PaymentCapture> = {
    created_at: {
        minSize: MIN_COLUMN_WIDTH.SHORT_DATE,
        cell(info) {
            return <TableCellShortDate value={info.getValue()} />
        }
    },
    type: {
        minSize: COLUMN_WIDTH_RATIO.CAPTURE_TYPE,
        cell(info) {
            const { type } = info.row.original
            return <TableCellText value={prettyPrintCasedWords(type)} />
        }
    },
    amount: {
        minSize: MIN_COLUMN_WIDTH.AMOUNT,
        cell(info) {
            const { currency } = info.row.original

            return <TableCellAmount amount={info.getValue()} currency={currency} />
        }
    },
    connected_account_id: {
        size: COLUMN_WIDTH_RATIO.CONNECTED_ACCOUNT,
        cell(info) {
            return <ConnectedAccountName connectedAccountId={info.getValue()} asPlainText={true} />
        }
    },
    value_date: {
        minSize: MIN_COLUMN_WIDTH.SHORT_DATE,
        cell(info) {
            return <TableCellShortDate value={info.getValue()} />
        }
    },
    reconciliation_status: {
        minSize: MIN_COLUMN_WIDTH.RECONCILIATION_STATUS,
        cell(info) {
            return <ReconciliationStatusLabel value={info.getValue()} />
        }
    }
}

export const PAYMENT_CAPTURE_FILTERS: FilterInputs<PaymentCaptureServiceFindAllQueryOptions> = {
    start_date: <DateInput name="start_date" />,
    end_date: <DateInput name="end_date" />,
    start_value_date: <DateInput name="start_value_date" />,
    end_value_date: <DateInput name="end_value_date" />,
    type: <PaymentCaptureTypeInput name="type" />,
    currency: <CurrencyInput name="currency" highlightedCurrencyCodes={HIGHLIGHTED_CURRENCY_CODES} />,
    amount_from: <AmountInput name="amount_from" />,
    amount_to: <AmountInput name="amount_to" />,
    connected_account_id: <ConnectedAccountsInput name="connected_account_id" isPlaceholderDisplayed={true} />,
    reconciliation_status: <ReconciliationStatusInput name="reconciliation_status" />,
    payment_reference: <TextInput name="payment_reference" />,
    source: <PaymentCaptureSourceInput name="source" />,
    canceled: <BooleanInput name="canceled" />
}

export const PAYMENT_CAPTURE_QUICK_FILTERS: QueryParamQuickFilters<PaymentCaptureServiceFindAllQueryOptions> = {
    reconciliation_status: [ReconciliationStatusSchema.enum.unreconciled]
}
