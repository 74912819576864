import { useQueryParams } from '@/hooks'
import { PageLayout } from '@/layouts'
import { HOME_PAGE_GRID_CONFIGURATION, HOME_PAGE_PRESELECTED_FILTERS } from './Home.page.const'
import { SimpleGrid } from '@chakra-ui/react'
import {
    BalancesWidget,
    LatestEventsWidget,
    PaymentCountersWidget,
    PaymentOrderReturnsWidget,
    ProcessedPaymentsWidget,
    ReconciliationsWidget,
    SimpleGridItem,
    TotalNumberPaymentsProcessedWidget,
    TotalValuePaymentsProcessedWidget
} from './@components'
import { WidgetsServiceFindAllQueryOptions } from '@/services'
import React from 'react'

export const HomePage: React.FC = () => {
    const { filterBy } = useQueryParams<WidgetsServiceFindAllQueryOptions>({
        filterBy: { configuration: { preselected: HOME_PAGE_PRESELECTED_FILTERS } }
    })

    return (
        <PageLayout className="Home">
            <SimpleGrid
                columns={HOME_PAGE_GRID_CONFIGURATION.GRID_ONE_COLUMNS}
                minChildWidth={HOME_PAGE_GRID_CONFIGURATION.MIN_CHILD_WIDTH}
                spacing={HOME_PAGE_GRID_CONFIGURATION.SPACING}>
                <SimpleGridItem>
                    <TotalNumberPaymentsProcessedWidget {...filterBy.parsedState} />
                </SimpleGridItem>
                <SimpleGridItem>
                    <TotalValuePaymentsProcessedWidget {...filterBy.parsedState} />
                </SimpleGridItem>
            </SimpleGrid>
            <SimpleGrid
                columns={HOME_PAGE_GRID_CONFIGURATION.GRID_TWO_COLUMNS}
                minChildWidth={HOME_PAGE_GRID_CONFIGURATION.MIN_CHILD_WIDTH}
                spacing={HOME_PAGE_GRID_CONFIGURATION.SPACING}
                marginTop={HOME_PAGE_GRID_CONFIGURATION.SPACING}>
                {/* Phase 1 */}
                <SimpleGridItem>
                    <ProcessedPaymentsWidget {...filterBy.parsedState} />
                </SimpleGridItem>
                <SimpleGridItem>
                    <PaymentCountersWidget {...filterBy.parsedState} />
                </SimpleGridItem>
                {/* Phase 2 */}
                <SimpleGridItem>
                    <BalancesWidget {...filterBy.parsedState} />
                </SimpleGridItem>
                <SimpleGridItem>
                    <ReconciliationsWidget {...filterBy.parsedState} />
                </SimpleGridItem>
                <SimpleGridItem>
                    <PaymentOrderReturnsWidget {...filterBy.parsedState} />
                </SimpleGridItem>
                <SimpleGridItem>
                    <LatestEventsWidget />
                </SimpleGridItem>
            </SimpleGrid>
        </PageLayout>
    )
}
