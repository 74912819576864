import { useAuth, usePermissions, useServices } from '@/hooks'
import { filterUserAccessQueryParams, PERMISSION } from '@/services'
import { DefinedInitialDataOptions, useQuery } from '@tanstack/react-query'
import { List } from '@webapps/numeral-ui-core'

export function useQueryFindAllConnectedAccountsBankNames(
    options?: Partial<DefinedInitialDataOptions<List<string>, Error>>
) {
    const { connectedAccountsService } = useServices()
    const { isUserLoggedIn, selectedUserAccess } = useAuth()
    const { hasPermission } = usePermissions()
    const isQueryEnabled =
        isUserLoggedIn && hasPermission(PERMISSION.SETTINGS_VIEW_CONNECTED_ACCOUNTS) && options?.enabled

    const paramsWithUserAccess = {
        ...filterUserAccessQueryParams(selectedUserAccess)
    }

    return useQuery<List<string>, Error>({
        ...options,
        enabled: isQueryEnabled,
        queryKey: [connectedAccountsService.url, paramsWithUserAccess],
        queryFn({ signal }) {
            return connectedAccountsService.findAllBankNames(paramsWithUserAccess, { signal })
        }
    })
}
