import { PaymentsIcon } from '@/components/@icons'
import { ParentPageLayout } from '@/layouts'
import {
    AuditTrailFilterByOption,
    AuditTrailPage,
    EventsPage,
    ExpectedPaymentCounterpartyPage,
    ExpectedPaymentCreatePage,
    ExpectedPaymentDetailsPage,
    ExpectedPaymentHeader,
    ExpectedPaymentsPage,
    ExpectedPaymentsReconciliationsList,
    IncomingPaymentCounterpartyPage,
    IncomingPaymentDetailsPage,
    IncomingPaymentHeader,
    IncomingPaymentReturnPage,
    IncomingPaymentsPage,
    IncomingPaymentsReconciliationsPage,
    InquiriesPage,
    InquiryDenyPage,
    InquiryDetailsHeader,
    InquiryDetailsPage,
    PaymentCaptureDetailsPage,
    PaymentCaptureHeader,
    PaymentCaptureReconciliationsPage,
    PaymentCapturesPage,
    PaymentFileDetailsHeader,
    PaymentFileDetailsPage,
    PaymentFilesPage,
    PaymentOrderCounterpartyPage,
    PaymentOrderCreatePage,
    PaymentOrderDetailsPage,
    PaymentOrderEditPage,
    PaymentOrderHeader,
    PaymentOrderReconciliationsPage,
    PaymentOrderRetriesPage,
    PaymentOrderReturnRequestPage,
    PaymentOrderUploadPage,
    PaymentOrdersPage,
    ReturnDetailsPage,
    ReturnHeader,
    ReturnRequestDenyPage,
    ReturnRequestDetailsHeader,
    ReturnRequestDetailsPage,
    ReturnRequestsPage,
    ReturnsPage,
    ReturnsReconciliationsPage
} from '@/pages'
import { NavigationRoute, getNestedRoutesWithTabLayout } from '@/providers'
import { ENTITY_FEATURE } from '@/services'
import { PaymentType, PaymentTypeSchema } from '@webapps/numeral-ui-core'
import { Navigate } from 'react-router-dom'
import { NAVIGATION_ROUTES_PROVIDER_RELATIVE_PATHS } from '../NavigationRoutesProvider.const'

function getCommonPaymentRoutes(paymentType: PaymentType) {
    const { PAYMENTS } = NAVIGATION_ROUTES_PROVIDER_RELATIVE_PATHS
    return [
        {
            title: 'app.payments.tabs.events.title',
            path: PAYMENTS.EVENTS,
            element: <EventsPage />
        },
        {
            title: 'app.payments.tabs.audit_trail.title',
            path: PAYMENTS.AUDIT_TRAIL,
            element: <AuditTrailPage objectType={paymentType} filterBy={AuditTrailFilterByOption.OBJECT} />
        }
    ]
}

export function getPaymentsNavigationRoutes(): NavigationRoute {
    const { NEW, EDIT, IMPORT, UUID, PAYMENTS, CANCEL, DENY, RETURN, UPLOAD } =
        NAVIGATION_ROUTES_PROVIDER_RELATIVE_PATHS
    const paymentOrderRoutes = [
        {
            title: 'app.payments.tabs.details.title',
            path: PAYMENTS.DETAILS,
            element: <PaymentOrderDetailsPage />
        },
        {
            title: 'app.payments.tabs.counterparties.title',
            path: PAYMENTS.COUNTERPARTIES,
            element: <PaymentOrderCounterpartyPage />
        },
        {
            title: 'app.payments.tabs.retries.title',
            path: PAYMENTS.RETRIES,
            element: <PaymentOrderRetriesPage />
        },
        {
            title: 'app.payments.tabs.reconciliations.title',
            path: PAYMENTS.RECONCILIATIONS,
            element: <PaymentOrderReconciliationsPage />
        },
        ...getCommonPaymentRoutes(PaymentTypeSchema.enum.payment_order)
    ]
    const expectedPaymentsRoutes = [
        {
            title: 'app.payments.tabs.details.title',
            path: PAYMENTS.DETAILS,
            element: <ExpectedPaymentDetailsPage />
        },
        {
            path: PAYMENTS.COUNTERPARTIES,
            title: 'app.payments.tabs.counterparties.title',
            element: <ExpectedPaymentCounterpartyPage />
        },
        {
            title: 'app.payments.tabs.reconciliations.title',
            path: PAYMENTS.RECONCILIATIONS,
            element: <ExpectedPaymentsReconciliationsList />
        },
        ...getCommonPaymentRoutes(PaymentTypeSchema.enum.expected_payment)
    ]
    const incomingPaymentsRoutes = [
        {
            title: 'app.payments.tabs.details.title',
            path: PAYMENTS.DETAILS,
            element: <IncomingPaymentDetailsPage />
        },
        {
            path: PAYMENTS.COUNTERPARTIES,
            title: 'app.payments.tabs.counterparties.title',
            element: <IncomingPaymentCounterpartyPage />
        },
        {
            title: 'app.payments.tabs.reconciliations.title',
            path: PAYMENTS.RECONCILIATIONS,
            element: <IncomingPaymentsReconciliationsPage />
        },
        ...getCommonPaymentRoutes(PaymentTypeSchema.enum.incoming_payment)
    ]
    const returnsRoutes = [
        {
            title: 'app.payments.tabs.details.title',
            path: PAYMENTS.DETAILS,
            element: <ReturnDetailsPage />
        },
        {
            title: 'app.payments.tabs.reconciliations.title',
            path: PAYMENTS.RECONCILIATIONS,
            element: <ReturnsReconciliationsPage />
        },
        ...getCommonPaymentRoutes(PaymentTypeSchema.enum.return)
    ]
    const returnRequestsRoutes = [
        {
            title: 'app.payments.tabs.details.title',
            path: PAYMENTS.DETAILS,
            element: <ReturnRequestDetailsPage />
        },
        ...getCommonPaymentRoutes(PaymentTypeSchema.enum.return_request)
    ]
    const inquiriesRoutes = [
        {
            title: 'app.payments.tabs.details.title',
            path: PAYMENTS.DETAILS,
            element: <InquiryDetailsPage />
        },
        ...getCommonPaymentRoutes(PaymentTypeSchema.enum.inquiry)
    ]
    const paymentCapturesRoutes = [
        {
            title: 'app.payments.tabs.details.title',
            path: PAYMENTS.DETAILS,
            element: <PaymentCaptureDetailsPage />
        },
        {
            title: 'app.payments.tabs.reconciliations.title',
            path: PAYMENTS.RECONCILIATIONS,
            element: <PaymentCaptureReconciliationsPage />
        },
        {
            title: 'app.payments.tabs.events.title',
            path: PAYMENTS.EVENTS,
            element: <EventsPage />
        }
    ]
    const paymentFileRoutes = [
        {
            title: 'app.payments.tabs.details.title',
            path: PAYMENTS.DETAILS,
            element: <PaymentFileDetailsPage />
        },
        ...getCommonPaymentRoutes(PaymentTypeSchema.enum.payment_file)
    ]

    return {
        title: 'app.payments.title',
        path: '/payments',
        configuration: {
            isGroup: true
        },
        icon: <PaymentsIcon />,
        routes: [
            { index: true, element: <Navigate replace={true} to="payment-orders" /> },
            {
                title: 'app.payments.payment_orders.title',
                path: 'payment-orders',
                element: <ParentPageLayout element={<PaymentOrdersPage />} />,
                routes: [
                    {
                        title: 'app.payments.payment_orders.upload.title',
                        path: UPLOAD,
                        element: <PaymentOrderUploadPage />
                    },
                    {
                        title: 'app.payments.payment_orders.create.title',
                        path: NEW,
                        element: <PaymentOrderCreatePage />
                    },
                    {
                        title: 'app.payments.payment_orders.edit.title',
                        path: EDIT,
                        element: <PaymentOrderEditPage />
                    },
                    {
                        title: 'app.payments.payment_orders.return.title',
                        path: RETURN,
                        element: <PaymentOrderReturnRequestPage />
                    },
                    {
                        title: 'app.payments.payment_orders.import.title',
                        path: IMPORT,
                        configuration: {
                            isDisabled: true
                        }
                    },
                    ...getNestedRoutesWithTabLayout(UUID, paymentOrderRoutes, {
                        header: <PaymentOrderHeader />
                    })
                ]
            },
            {
                title: 'app.payments.incoming_payments.title',
                path: 'incoming-payments',
                element: <ParentPageLayout element={<IncomingPaymentsPage />} />,
                routes: [
                    {
                        title: 'app.payments.incoming_payments.return.title',
                        path: RETURN,
                        element: <IncomingPaymentReturnPage />
                    },
                    ...getNestedRoutesWithTabLayout(UUID, incomingPaymentsRoutes, {
                        header: <IncomingPaymentHeader />
                    })
                ]
            },
            {
                title: 'app.payments.returns.title',
                path: 'returns',
                element: <ParentPageLayout element={<ReturnsPage />} />,
                routes: getNestedRoutesWithTabLayout(UUID, returnsRoutes, {
                    header: <ReturnHeader />
                })
            },
            {
                title: 'app.payments.return_requests.title',
                path: 'return-requests',
                configuration: {
                    isForFinancialInstitution: true
                },
                element: <ParentPageLayout element={<ReturnRequestsPage />} />,
                routes: [
                    {
                        title: 'app.payments.return_requests.deny.title',
                        path: CANCEL,
                        element: <ReturnRequestDenyPage />
                    },
                    ...getNestedRoutesWithTabLayout(UUID, returnRequestsRoutes, {
                        header: <ReturnRequestDetailsHeader />
                    })
                ]
            },
            {
                title: 'app.payments.inquiries.title',
                path: 'inquiries',
                configuration: {
                    isForFinancialInstitution: true
                },
                element: <ParentPageLayout element={<InquiriesPage />} />,
                routes: [
                    {
                        title: 'app.payments.inquiries.deny.title',
                        path: DENY,
                        element: <InquiryDenyPage />
                    },
                    ...getNestedRoutesWithTabLayout(UUID, inquiriesRoutes, {
                        header: <InquiryDetailsHeader />
                    })
                ]
            },
            {
                title: 'app.payments.expected_payments.title',
                path: 'expected-payments',
                element: <ParentPageLayout element={<ExpectedPaymentsPage />} />,
                routes: [
                    {
                        title: 'app.payments.expected_payments.create.title',
                        path: NEW,
                        element: <ExpectedPaymentCreatePage />
                    },
                    ...getNestedRoutesWithTabLayout(UUID, expectedPaymentsRoutes, {
                        header: <ExpectedPaymentHeader />
                    })
                ]
            },
            {
                title: 'app.payments.payment_captures.title',
                path: 'payment-captures',
                configuration: {
                    feature: ENTITY_FEATURE.PAYMENT_CAPTURE
                },
                element: <ParentPageLayout element={<PaymentCapturesPage />} />,
                routes: getNestedRoutesWithTabLayout(UUID, paymentCapturesRoutes, {
                    header: <PaymentCaptureHeader />
                })
            },
            {
                title: 'app.payments.payment_files.title',
                path: 'payment-files',
                element: <ParentPageLayout element={<PaymentFilesPage />} />,
                routes: [
                    ...getNestedRoutesWithTabLayout(UUID, paymentFileRoutes, {
                        header: <PaymentFileDetailsHeader />
                    })
                ]
            }
        ]
    }
}
