import { isEmptyRecord } from '@/utils'
import { accountNumberFormatter } from '@/utils/@formatters'
import { Box, Text } from '@chakra-ui/react'
import { InternalAccount } from '@webapps/numeral-ui-core'
import { AccountHolderName, BankCode, FormattedTextIntl } from '../@misc'
import { EntityDetailCellHolderAddress, EntityDetailRow, EntityDetailRowCustomizers } from '../EntityDetail'

export const INTERNAL_ACCOUNT_DETAILS_SUMMARY_ROWS: EntityDetailRow<InternalAccount>[] = [
    'name',
    'type',
    'account_holder_id',
    'holder_name',
    'account_number',
    'bank_code',
    'holder_address'
]

export const INTERNAL_ACCOUNT_DETAILS_SUMMARY_ROW_CUSTOMIZERS: EntityDetailRowCustomizers<InternalAccount> = {
    type: {
        cell(item) {
            return <FormattedTextIntl messageId={`api.internal_account.type.${item.type}`} />
        }
    },
    account_number: {
        cell(item) {
            const formattedValue = accountNumberFormatter(item.account_number)
            return <Text>{formattedValue}</Text>
        }
    },
    account_holder_id: {
        cell(item) {
            return <AccountHolderName accountHolderId={item.account_holder_id} asPlainText={true} />
        }
    },
    bank_code: {
        cell(item) {
            return <BankCode value={item.bank_code} />
        }
    },
    holder_address: {
        cell(item) {
            // @todo this should work without adding a padding top fix.
            const temporaryPaddingTopFix = isEmptyRecord(item?.holder_address) ? '0' : '16px'

            return (
                <Box paddingTop={temporaryPaddingTopFix}>
                    <EntityDetailCellHolderAddress value={item.holder_address} />
                </Box>
            )
        }
    }
}
