import {
    FilterInputs,
    TableCellAccount,
    TableCellShortDate,
    TableColumnCustomizers,
    TableStateWithEntity
} from '@/components'
import {
    AccountHolderInput,
    AmountInput,
    BooleanInput,
    ConnectedAccountsInput,
    CurrencyInput,
    DateInput,
    ExternalAccountsInput,
    PaymentDirectionInput,
    ReconciliationStatusInput,
    TextInput
} from '@/components/@inputs'
import {
    AmountRange,
    ConnectedAccountName,
    DateRange,
    Direction,
    ReconciliationStatusLabel,
    StatusLabel
} from '@/components/@misc'
import { COLUMN_WIDTH_RATIO, DOCUMENTATION_BASE_URL, HIGHLIGHTED_CURRENCY_CODES, MIN_COLUMN_WIDTH } from '@/constants'
import { QueryParamQuickFilters } from '@/hooks'
import { Text } from '@chakra-ui/react'
import {
    ExpectedPayment,
    ExpectedPaymentsServiceFindAllQueryOptions,
    ReconciliationStatusSchema
} from '@webapps/numeral-ui-core'
import { ExpectedPaymentsCustomColumns } from './ExpectedPayments.types'

export const EXPECTED_PAYMENT_DOCUMENTATION_LINK = `${DOCUMENTATION_BASE_URL}/expected-payment-object`

export const EXPECTED_PAYMENT_TABLE_STATIC_STATE: TableStateWithEntity<ExpectedPayment, ExpectedPaymentsCustomColumns> =
    {
        columnOrder: Object.freeze([
            'created_at',
            'direction',
            'amount_interval',
            'date_interval',
            'connected_account_id',
            'external_account',
            'canceled_at', // Used for Status
            'reconciliation_status'
        ])
    }

export const EXPECTED_PAYMENT_TABLE_COLUMN_CUSTOMIZERS: TableColumnCustomizers<
    ExpectedPayment,
    ExpectedPaymentsCustomColumns
> = {
    created_at: {
        minSize: MIN_COLUMN_WIDTH.SHORT_DATE,
        cell(info) {
            return <TableCellShortDate value={info.getValue()} />
        }
    },
    direction: {
        size: COLUMN_WIDTH_RATIO.DIRECTION,
        cell(info) {
            return <Direction value={info.getValue()} />
        }
    },
    amount_interval: {
        minSize: MIN_COLUMN_WIDTH.AMOUNT_RANGE,
        cell(info) {
            const { amount_from, amount_to, currency } = info.row.original
            return (
                <Text textAlign="right">
                    <AmountRange from={amount_from} to={amount_to} currency={currency} />
                </Text>
            )
        }
    },
    date_interval: {
        minSize: MIN_COLUMN_WIDTH.SHORT_DATE_RANGE,
        cell(info) {
            const { start_date, end_date } = info.row.original
            return <DateRange from={start_date} to={end_date} />
        }
    },
    connected_account_id: {
        size: COLUMN_WIDTH_RATIO.CONNECTED_ACCOUNT,
        cell(info) {
            return <ConnectedAccountName connectedAccountId={info.getValue()} asPlainText={true} />
        }
    },
    external_account: {
        size: COLUMN_WIDTH_RATIO.ACCOUNT,
        cell(info) {
            return <TableCellAccount value={info.getValue()} />
        }
    },
    canceled_at: {
        minSize: MIN_COLUMN_WIDTH.STATUS,
        cell(info) {
            if (info.getValue()) {
                return <StatusLabel value="canceled" />
            } else {
                return <StatusLabel value="created" />
            }
        }
    },
    reconciliation_status: {
        minSize: MIN_COLUMN_WIDTH.RECONCILIATION_STATUS,
        cell(info) {
            return <ReconciliationStatusLabel value={info.getValue()} />
        }
    }
}

export const EXPECTED_PAYMENT_FILTERS: FilterInputs<ExpectedPaymentsServiceFindAllQueryOptions> = {
    start_date: <DateInput name="start_date" />,
    end_date: <DateInput name="end_date" />,
    direction: <PaymentDirectionInput name="direction" isPlaceholderDisplayed={true} />,
    currency: <CurrencyInput name="currency" highlightedCurrencyCodes={HIGHLIGHTED_CURRENCY_CODES} />,
    amount_from: <AmountInput name="amount_from" />,
    amount_to: <AmountInput name="amount_to" />,
    connected_account_id: <ConnectedAccountsInput name="connected_account_id" isPlaceholderDisplayed={true} />,
    reconciliation_status: <ReconciliationStatusInput name="reconciliation_status" />,
    description: <TextInput name="description" />,
    internal_account_number: <TextInput name="internal_account_number" />,
    external_account_holder_id: <AccountHolderInput name="external_account_holder_id" />,
    external_account_holder_name: <TextInput name="external_account_holder_name" isPlaceholderDisplayed={true} />,
    external_account_number: <TextInput name="external_account_number" />,
    external_account_id: <ExternalAccountsInput name="external_account_id" isPlaceholderDisplayed={true} />,
    canceled: <BooleanInput name="canceled" />
}

export const EXPECTED_PAYMENT_QUICK_FILTERS: QueryParamQuickFilters<ExpectedPaymentsServiceFindAllQueryOptions> = {
    reconciliation_status: [ReconciliationStatusSchema.enum.unreconciled]
}
