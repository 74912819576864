import { WILDCARD_SYMBOL } from '@/constants'
import {
    QUERY_PARAMS_RESERVED_NAMES,
    QueryParamConfigurationFilterBy,
    QueryParamStateFilterBy,
    useQuickFilter
} from '@/hooks'
import { prettyPrintCasedWords } from '@/utils'
import { Fade, Tab, TabIndicator, TabList, Tabs } from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import { chain, invoke, isBoolean, isNumber, toArray } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { isRecordIncluded } from '../../TableHeader.utils'
import { getFilterByInitialValues } from '../TableHeaderFilterBy'
import { rotateAllWildcardsAcrossQuickFilters } from './TableHeaderQuickFilter.utils'

export interface TableHeaderQuickFilterProps<T> extends QueryParamConfigurationFilterBy<T> {
    value: QueryParamStateFilterBy<T>
}

export function TableHeaderQuickFilter<T = void>({ filters, value }: TableHeaderQuickFilterProps<T>) {
    const quickFilter = useQuickFilter<T>()
    const quickFilterEntriesWrapper = useMemo(() => {
        const rotatedQuickFilterEntries = rotateAllWildcardsAcrossQuickFilters(quickFilter.quickFilters)
        return chain(rotatedQuickFilterEntries)
    }, [quickFilter.quickFilters])

    const [index, setIndex] = useState<number | undefined>(undefined)

    const { values, resetForm } = useFormikContext<QueryParamStateFilterBy<T>>()
    const onChange = useCallback(
        (value: QueryParamStateFilterBy<T>) => {
            invoke(quickFilter, 'onChange', value)

            resetForm({
                values: {
                    // Preserve (`search`, `groupBy` and others):
                    ...values,
                    // Reset all other `filterBy initial values:
                    ...getFilterByInitialValues<QueryParamStateFilterBy<T>>(filters),
                    // Commit new filter value:
                    ...value
                }
            })
        },
        [quickFilter, resetForm, values, filters]
    )
    const onReset = useCallback(() => {
        invoke(quickFilter, 'onReset')

        resetForm({
            values: {
                ...values,
                ...getFilterByInitialValues<QueryParamStateFilterBy<T>>(filters)
            }
        })
    }, [quickFilter, resetForm, values, filters])
    const filterTabs = useMemo(() => {
        return quickFilterEntriesWrapper
            .keys()
            .map((key: keyof T, index: number) => {
                if (key === WILDCARD_SYMBOL) {
                    return (
                        <Tab onClick={onReset} key={index}>
                            <FormattedMessage id="app.common.form.input.select.placeholder" />
                        </Tab>
                    )
                }

                return quickFilterEntriesWrapper
                    .get(key)
                    .thru(toArray)
                    .map((value) => {
                        const defaultFilterName = isBoolean(value) ? key : value
                        const quickFilterName = prettyPrintCasedWords(defaultFilterName)
                        const onClick = () => {
                            const state = Object.create(null)
                            state[key] = value
                            onChange(state)
                        }

                        return (
                            <Tab
                                onClick={onClick}
                                title={quickFilterName}
                                noOfLines={1}
                                whiteSpace="nowrap"
                                key={index}>
                                {quickFilterName}
                            </Tab>
                        )
                    })
                    .value()
            })
            .value()
    }, [onChange, quickFilter.quickFilters, quickFilterEntriesWrapper])
    const fadeIn = useMemo(() => isNumber(index), [index])

    useEffect(() => {
        const quickFilterStateWrapper = chain(quickFilter.state)
        let computedActiveIndex: number

        if (isRecordIncluded(quickFilter.state, value)) {
            computedActiveIndex = quickFilterEntriesWrapper
                .keys()
                .flatMap((key: keyof T) => quickFilterEntriesWrapper.get(key).value())
                .findIndex((item) =>
                    quickFilterStateWrapper
                        .includes(item as QueryParamStateFilterBy<T>[Exclude<keyof T, QUERY_PARAMS_RESERVED_NAMES>])
                        .value()
                )
                .value()
        } else {
            computedActiveIndex = quickFilterEntriesWrapper
                .flatMap()
                .value()
                .findIndex((item) => item === WILDCARD_SYMBOL)
        }

        setIndex(computedActiveIndex)
    }, [quickFilterEntriesWrapper, quickFilter.state, value])

    return (
        <Tabs className="TableHeaderQuickFilter" position="relative" variant="unstyled" paddingTop="8px" index={index}>
            <TabList>{filterTabs}</TabList>
            <Fade in={fadeIn}>
                <TabIndicator
                    height="2px"
                    bg="numeralAccent.500"
                    borderRadius="1px"
                    data-testid="table-header-quickfilters-tab-indicator"
                />
            </Fade>
        </Tabs>
    )
}
