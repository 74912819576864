import { chain, get } from 'lodash'
import { QueryParamConfigurationFilterBy, QueryParamStateFilterBy } from '@/hooks'

/**
 * @description
 * - Cross-checks quick-filters `state` with configuration to ensure correct behaviour;
 * - Supports multiple more than one value for quick-filters;
 */
export function getQuickFilterStateValue<T>(
    quickFilters: QueryParamConfigurationFilterBy<T>['quickFilters'],
    quickFilterState?: QueryParamStateFilterBy<T>
): string {
    const quickFilterStateWrapper = chain(quickFilterState)
    return (
        quickFilterStateWrapper
            .keys()
            .find((key) => {
                const stateValue = quickFilterStateWrapper.get(key).value()
                const quickFiltersValue = get(quickFilters, key)
                return quickFiltersValue?.includes(stateValue)
            })
            /**
             * @description
             *  If key matches quick-filters configuration data, return state value for that key:
             */
            .thru((key) => (key ? quickFilterStateWrapper.get(key).value() : undefined))
            .value()
    )
}
