import { useAuth, useConfiguration, usePermissions, useServices } from '@/hooks'
import { filterUserAccessQueryParams, PERMISSION } from '@/services'
import { UndefinedInitialDataInfiniteOptions, useInfiniteQuery } from '@tanstack/react-query'
import { InternalAccount, InternalAccountsServiceFindAllQueryOptions, List } from '@webapps/numeral-ui-core'
import { paginationQueryOptions } from '../paginationQueryOptions.const'

export function useQueryFindAllInternalAccounts(
    params?: Partial<InternalAccountsServiceFindAllQueryOptions>,
    options?: Partial<UndefinedInitialDataInfiniteOptions<List<InternalAccount>, Error>>
) {
    const { internalAccountsService } = useServices()
    const { selectedUserAccess } = useAuth()
    const { pagination } = useConfiguration()
    const { hasPermission } = usePermissions()

    const isQueryEnabled = hasPermission(PERMISSION.COUNTERPARTIES_VIEW_INTERNAL_ACCOUNTS) && options?.enabled

    const paramsWithUserAccess: typeof params = {
        limit: pagination.limit,
        ...params,
        ...filterUserAccessQueryParams(selectedUserAccess)
    }

    return useInfiniteQuery<List<InternalAccount>, Error>({
        ...options,
        enabled: isQueryEnabled,
        initialPageParam: undefined,
        queryKey: [internalAccountsService.url, paramsWithUserAccess],
        queryFn({ signal, pageParam }) {
            return internalAccountsService.findAll(
                { ...paramsWithUserAccess, ...(pageParam as typeof params) },
                { signal }
            )
        },
        getNextPageParam(lastPage) {
            return paginationQueryOptions.getNextPageParam(lastPage, pagination.limit)
        }
    })
}
