import { amountFormatter, connectedAccountNameFormatter } from '@/utils/@formatters'
import {
    ChartBarVerticalStack,
    getChartTooltipLabelFormatterForAxis,
    transformWidgetTimeSeriesToChartData
} from '@/components/@charts'
import { useIntl } from 'react-intl'
import {
    PERMISSION,
    WIDGET_TYPE,
    WidgetSeriesReservedLabels,
    WidgetsServiceBalancesQueryOptions,
    WidgetsServiceFindAllQueryOptions
} from '@/services'
import { useMemo } from 'react'
import { ChartTypeRegistry, TooltipItem } from 'chart.js'
import { Widget, WidgetQueryFallback } from '@/components'
import { usePermissions, useQueriesBalancesConnectedAccounts, useQueryBalances, useQueryParams } from '@/hooks'
import { chain, isEmpty, isEqual } from 'lodash'
import { HOME_PAGE_COMMON_WIDGET_PRESELECTED_FILTERS } from '@/pages/Home/Home.page.const'
import { If } from '@/components/@misc'
import { isEmptyRecord } from '@/utils'
import { BalancesWidgetOptions } from './@components'
import { Y_AXIS_AMOUNT_FORMAT_OPTIONS } from './BalancesWidget.const'
import { UuidSchema } from '@/utils/@validators'

export const BalancesWidget: React.FC<WidgetsServiceFindAllQueryOptions> = (props) => {
    const intl = useIntl()
    const { filterBy } = useQueryParams<WidgetsServiceBalancesQueryOptions>({
        filterBy: {
            configuration: {
                preselected: {
                    ...props,
                    ...HOME_PAGE_COMMON_WIDGET_PRESELECTED_FILTERS
                },
                uniqueId: WIDGET_TYPE.Balances
            }
        }
    })
    const query = useQueryBalances(filterBy.parsedState)
    const connectedAccountIds = useMemo(() => {
        return chain(query.data)
            .get('data_series')
            .filter(({ label }) => label !== WidgetSeriesReservedLabels.Other)
            .map(({ label }) => label)
            .value()
    }, [query])
    const queryBalancesConnectedAccounts = useQueriesBalancesConnectedAccounts(connectedAccountIds, {
        enabled: query.isSuccess
    })
    const title = useMemo(
        () =>
            intl.formatMessage({
                id: 'app.widgets.balances.title'
            }),
        [intl]
    )
    const chartData = useMemo(() => {
        return transformWidgetTimeSeriesToChartData(query.data, intl, {
            dataset(value) {
                const hasOtherLabel = isEqual(value, WidgetSeriesReservedLabels.Other)
                const hasConnectedAccounts = !isEmptyRecord(queryBalancesConnectedAccounts.data)
                const hasValidUuidLabel = UuidSchema.safeParse(value).success

                switch (true) {
                    case hasOtherLabel: {
                        return intl?.formatMessage({ id: 'app.common.other' })
                    }

                    case hasValidUuidLabel && hasConnectedAccounts: {
                        return chain(queryBalancesConnectedAccounts.data)
                            .get(value)
                            .thru(connectedAccountNameFormatter)
                            .value()
                    }

                    default: {
                        return value
                    }
                }
            }
        })
    }, [query.data, queryBalancesConnectedAccounts.data, intl])
    const chartOptions = useMemo(() => {
        const { currency } = filterBy.parsedState
        const scales = {
            y: {
                ticks: {
                    callback(value: string | number) {
                        return amountFormatter(intl, value as number, currency, true, Y_AXIS_AMOUNT_FORMAT_OPTIONS)
                    }
                }
            }
        }
        const plugins = {
            tooltip: {
                callbacks: {
                    label(context: TooltipItem<keyof ChartTypeRegistry>) {
                        const yAxisLabelFormatter = getChartTooltipLabelFormatterForAxis('y', (value) => {
                            return amountFormatter(intl, value as number, currency)
                        })
                        return yAxisLabelFormatter(context)
                    }
                }
            }
        }

        return {
            scales,
            plugins
        }
    }, [intl, filterBy.parsedState])
    const isDataEmpty = useMemo(() => isEmpty(query.data?.data_series), [query])
    const { hasPermission } = usePermissions()
    const hasViewBalancesPermission = useMemo(() => {
        return hasPermission(PERMISSION.HOME_VIEW_BALANCES)
    }, [hasPermission])

    return (
        <If condition={hasViewBalancesPermission}>
            <Widget title={title} options={<BalancesWidgetOptions filterBy={filterBy} isDisabled={query.isLoading} />}>
                <WidgetQueryFallback isLoading={query.isLoading} isError={query.isError} isDataEmpty={isDataEmpty}>
                    <ChartBarVerticalStack
                        data={chartData}
                        options={chartOptions}
                        showLegend={true}
                        isLoading={queryBalancesConnectedAccounts.isLoading}
                    />
                </WidgetQueryFallback>
            </Widget>
        </If>
    )
}
