import { QueryFallback, RestrictedSection, Table, TableHeader, useTableRowNavigation } from '@/components'
import { useNavigationRoutes, useQueryFindAllFiles, useQueryParams } from '@/hooks'
import { PageHeaderLayout, PageLayout } from '@/layouts'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { PERMISSION } from '@/services'
import { queryDataAggregation } from '@/utils'
import { FilesServiceFindAllQueryOptions, NumeralFile } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import {
    FILES_DOCUMENTATION_LINK,
    FILES_FILTERS,
    FILES_TABLE_COLUMN_CUSTOMIZERS,
    FILES_TABLE_STATIC_STATE
} from './Files.page.const'

export const FilesPage: React.FC = () => {
    const intl = useIntl()
    const { relativePaths } = useNavigationRoutes()
    const title = useMemo<string>(() => intl.formatMessage({ id: 'app.files.title' }), [intl])
    const { search, filterBy } = useQueryParams<FilesServiceFindAllQueryOptions>('search', {
        filterBy: { configuration: { filters: FILES_FILTERS } }
    })
    const query = useQueryFindAllFiles({ ...search.parsedState, ...filterBy.parsedState })
    const data = useMemo(() => queryDataAggregation(query.data), [query])
    const isDataEmpty = useMemo<boolean>(() => isEmpty(data), [data])

    const onTableRowClick = useTableRowNavigation<NumeralFile>('id', relativePaths.FILES.DETAILS)

    return (
        <PageLayout>
            <PageHeaderLayout title={title} showBackButton={false} />
            <RestrictedSection permission={PERMISSION.FILES_VIEW_FILES}>
                <TableHeader<FilesServiceFindAllQueryOptions>
                    objectType={ApiObjectTypeExtendedSchema.enum.file}
                    isLoading={query.isLoading}
                    search={search}
                    filterBy={filterBy}
                />
                <QueryFallback
                    objectType={ApiObjectTypeExtendedSchema.enum.file}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError}
                    isFilteringOrSearching={search.isActive || filterBy.isActive}
                    isDataEmpty={isDataEmpty}
                    learnMoreLinkUrl={FILES_DOCUMENTATION_LINK}
                    customEmptyStateDescriptionKey="app.files.empty_state.description">
                    <Table<NumeralFile>
                        data={data}
                        onRowClick={onTableRowClick}
                        onScrollToBottom={query.fetchNextPage}
                        isLoading={query.hasNextPage}
                        state={FILES_TABLE_STATIC_STATE}
                        columnCustomizers={FILES_TABLE_COLUMN_CUSTOMIZERS}
                    />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
