import { Flex, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { PAYMENT_COUNTERS_AVAILABLE_OPTIONS } from '../PaymentCountersWidget.const'
import { prettyPrintCasedWords } from '@/utils'
import { useIntl } from 'react-intl'
import { useMemo } from 'react'
import { QueryParamTypes, UseQueryParamsResult } from '@/hooks'
import { WidgetsServicePaymentCountersQueryOptions } from '@/services'

interface PaymentCountersWidgetTitleProps {
    filterBy: UseQueryParamsResult<QueryParamTypes.FilterBy, WidgetsServicePaymentCountersQueryOptions>
    isDisabled?: boolean
}

export const PaymentCountersWidgetTitle: React.FC<PaymentCountersWidgetTitleProps> = ({ filterBy, isDisabled }) => {
    const intl = useIntl()
    const label = useMemo(() => {
        return intl.formatMessage(
            { id: 'app.common.pluralization.label' },
            {
                count: 2,
                singular: prettyPrintCasedWords(filterBy.parsedState?.payment_type, true)
            }
        )
    }, [intl, filterBy])

    return (
        <Menu isLazy={true}>
            <MenuButton disabled={isDisabled}>
                <Flex alignItems="center">
                    <Text fontSize="16px" fontWeight="400" marginRight="16px" aria-label={label} noOfLines={1}>
                        {label}
                    </Text>
                    <FontAwesomeIcon icon={faChevronDown} size="xs" />
                </Flex>
            </MenuButton>
            <MenuList borderRadius="var(--numeral-ui-primary-border-radius)">
                {PAYMENT_COUNTERS_AVAILABLE_OPTIONS.map((value, index) => {
                    const onClick = () => {
                        filterBy.setState({
                            payment_type: value
                        })
                    }
                    const label = intl.formatMessage(
                        {
                            id: 'app.common.pluralization.label'
                        },
                        {
                            count: 2,
                            singular: prettyPrintCasedWords(value)
                        }
                    )

                    return (
                        <MenuItem key={index} onClick={onClick}>
                            {label}
                        </MenuItem>
                    )
                })}
            </MenuList>
        </Menu>
    )
}
