import {
    FilterInputs,
    TableCellShortDate,
    TableCellText,
    TableColumnCustomizers,
    TableStateWithEntity
} from '@/components'
import {
    AccountHolderInput,
    CountryInput,
    DateInput,
    ExternalAccountStatusInput,
    TextInput
} from '@/components/@inputs'
import { BankCode, ExternalAccountVerificationStatus, StatusLabel } from '@/components/@misc'
import { COLUMN_WIDTH_RATIO, DOCUMENTATION_BASE_URL, MIN_COLUMN_WIDTH, WILDCARD_SYMBOL } from '@/constants'
import { QueryParamQuickFilters, QueryParamStateFilterBy } from '@/hooks'
import { accountNumberFormatter } from '@/utils/@formatters'
import {
    ExternalAccount,
    ExternalAccountStatusSchema,
    ExternalAccountsServiceFindAllQueryOptions
} from '@webapps/numeral-ui-core'

export const EXTERNAL_ACCOUNT_DOCUMENTATION_LINK = `${DOCUMENTATION_BASE_URL}/external-account-object`

export const EXTERNAL_ACCOUNT_TABLE_STATIC_STATE: TableStateWithEntity<ExternalAccount> = {
    columnOrder: Object.freeze([
        'created_at',
        'name',
        'holder_name',
        'account_number',
        'bank_code',
        'status',
        'account_verification'
    ])
}

export const EXTERNAL_ACCOUNT_TABLE_COLUMN_CUSTOMIZERS: TableColumnCustomizers<ExternalAccount> = {
    created_at: {
        minSize: MIN_COLUMN_WIDTH.SHORT_DATE,
        size: COLUMN_WIDTH_RATIO.SHORT_DATE,
        cell(info) {
            return <TableCellShortDate value={info.getValue()} />
        }
    },
    name: {
        cell(info) {
            return <TableCellText value={info.getValue()} />
        }
    },
    holder_name: {
        cell(info) {
            return <TableCellText value={info.getValue()} />
        }
    },
    account_number: {
        minSize: MIN_COLUMN_WIDTH.ACCOUNT_NUMBER,
        cell(info) {
            const formattedValue = accountNumberFormatter(info.getValue())

            return <TableCellText value={formattedValue} />
        }
    },
    bank_code: {
        cell(info) {
            return <BankCode value={info.getValue()} />
        }
    },
    status: {
        minSize: MIN_COLUMN_WIDTH.SHORT_STATUS,
        cell(info) {
            return <StatusLabel value={info.getValue()} />
        }
    },
    account_verification: {
        cell(info) {
            return <ExternalAccountVerificationStatus accountVerification={info.getValue()} />
        }
    }
}

export const EXTERNAL_ACCOUNT_FILTERS: FilterInputs<ExternalAccountsServiceFindAllQueryOptions> = {
    start_date: <DateInput name="start_date" />,
    end_date: <DateInput name="end_date" />,
    account_holder_id: <AccountHolderInput name="account_holder_id" isPlaceholderDisplayed={true} />,
    holder_name: <TextInput name="holder_name" />,
    account_number: <TextInput name="account_number" />,
    country: <CountryInput name="country" isPlaceholderDisplayed={true} />,
    status: <ExternalAccountStatusInput name="status" />
}

export const EXTERNAL_ACCOUNT_PRESELECTED_FILTERS: QueryParamStateFilterBy<ExternalAccountsServiceFindAllQueryOptions> =
    Object.freeze({
        status: ExternalAccountStatusSchema.enum.approved
    })

export const EXTERNAL_ACCOUNT_QUICK_FILTERS: QueryParamQuickFilters<ExternalAccountsServiceFindAllQueryOptions> = {
    status: [ExternalAccountStatusSchema.enum.approved, ExternalAccountStatusSchema.enum.pending_approval],
    [WILDCARD_SYMBOL]: [WILDCARD_SYMBOL]
}
