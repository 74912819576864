import { useAuth, usePermissions, useServices } from '@/hooks'
import { filterUserAccessQueryParams, PERMISSION } from '@/services'
import { useQuery } from '@tanstack/react-query'
import type { UndefinedInitialDataOptions } from '@tanstack/react-query/src/queryOptions'
import { InternalAccount, Uuid } from '@webapps/numeral-ui-core'

export function useQueryFindByIdInternalAccount(
    id?: Uuid,
    options?: Partial<UndefinedInitialDataOptions<InternalAccount, Error>>
) {
    const { isUserLoggedIn, selectedUserAccess } = useAuth()
    const { internalAccountsService } = useServices()

    const { hasPermission } = usePermissions()

    const isQueryEnabled =
        isUserLoggedIn && hasPermission(PERMISSION.COUNTERPARTIES_VIEW_INTERNAL_ACCOUNTS) && options?.enabled
    const paramsWithUserAccess = {
        ...filterUserAccessQueryParams(selectedUserAccess)
    }

    return useQuery<InternalAccount, Error>({
        ...options,
        enabled: isQueryEnabled,
        queryKey: [internalAccountsService.url, { id }],
        queryFn({ signal }) {
            return internalAccountsService.findById(id as string, { signal, params: paramsWithUserAccess })
        }
    })
}
