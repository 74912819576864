import {
    EntityDetailCellTextWithCopyButton,
    EntityDetailRow,
    EntityDetailRowCustomizers,
    TableCellAccount
} from '@/components'
import { ConnectedAccountName, FormattedDateTime, FormattedTextIntl, StatusLabel } from '@/components/@misc'
import { Account, DirectDebitMandate } from '@webapps/numeral-ui-core'
import { DirectDebitMandatesDetailsRelatedObjects } from './@components'
import { DirectDebitMandateDetailsCustomRows } from './DirectDebitMandateDetails.types'
import { prettyPrintCasedWords } from '@/utils'
import { Text } from '@chakra-ui/react'
import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { chain } from 'lodash'

export const DIRECT_DEBIT_MANDATE_DETAILS_ROWS: EntityDetailRow<
    DirectDebitMandate,
    DirectDebitMandateDetailsCustomRows
>[] = [
    'id',
    'created_at',
    'connected_account_id',
    'originating_account',
    'receiving_account',
    'direction',
    'type',
    'sequence',
    'reference',
    'signature_date',
    'status',
    'creditor_identifier',
    'related_objects',
    'metadata'
]

export const DIRECT_DEBIT_MANDATE_DETAILS_ROW_CUSTOMIZERS: EntityDetailRowCustomizers<
    DirectDebitMandate,
    DirectDebitMandateDetailsCustomRows
> = {
    id: {
        cell(item) {
            return <EntityDetailCellTextWithCopyButton value={item.id} />
        }
    },
    created_at: {
        cell(item) {
            return <FormattedDateTime value={item.created_at} />
        }
    },
    connected_account_id: {
        cell(item) {
            return <ConnectedAccountName connectedAccountId={item.connected_account_id} />
        }
    },
    originating_account: {
        cell(item) {
            return <TableCellAccount value={item.originating_account} />
        }
    },
    receiving_account: {
        cell(item) {
            return <TableCellAccount value={item.receiving_account} />
        }
    },
    direction: {
        cell(item) {
            const value = chain<DirectDebitMandate>(item).get('direction').value()
            const formattedValue = prettyPrintCasedWords(value)
            return <Text>{formattedValue || EMPTY_VALUE_PLACEHOLDER}</Text>
        }
    },
    type: {
        cell(item) {
            const type = item.type
            return <FormattedTextIntl messageId={`api.direct_debit_mandate.type.${type}`} defaultMessage={type} />
        }
    },
    sequence: {
        cell(item) {
            const sequence = item.sequence
            return (
                <FormattedTextIntl
                    messageId={`api.direct_debit_mandate.sequence.${sequence}`}
                    defaultMessage={sequence}
                />
            )
        }
    },
    status: {
        cell(item) {
            return <StatusLabel value={item.status} />
        }
    },
    creditor_identifier: {
        cell(item) {
            const value = chain<Account>(item.originating_account)
                .get('creditor_identifier', EMPTY_VALUE_PLACEHOLDER)
                .value()
            return <Text>{value}</Text>
        }
    },
    related_objects: {
        cell(item) {
            return <DirectDebitMandatesDetailsRelatedObjects directDebitMandate={item} />
        }
    },
    metadata: {
        nested: true
    }
}
