import { Form, FormAutoSubmit } from '@/components'
import { WidgetsServiceBalancesQueryOptions } from '@/services'
import { Flex } from '@chakra-ui/react'
import { BankNameInput, CurrencyInput } from '@/components/@inputs'
import { QueryParamTypes, UseQueryParamsResult } from '@/hooks'
import { HIGHLIGHTED_CURRENCY_CODES } from '@/constants'

interface BalancesWidgetOptionsProps {
    filterBy: UseQueryParamsResult<QueryParamTypes.FilterBy, WidgetsServiceBalancesQueryOptions>
    isDisabled?: boolean
}

export const BalancesWidgetOptions: React.FC<BalancesWidgetOptionsProps> = ({ filterBy, isDisabled }) => {
    return (
        <Form<typeof filterBy.initialState> initialValues={filterBy.initialState} onSubmit={filterBy.setState}>
            {({ values }) => {
                return (
                    <Flex minWidth="350px" gap="8px">
                        <CurrencyInput
                            name="currency"
                            isClearable={false}
                            isLabelDisplayed={false}
                            value={values?.currency}
                            highlightedCurrencyCodes={HIGHLIGHTED_CURRENCY_CODES}
                            isDisabled={isDisabled}
                        />
                        <BankNameInput
                            name="bank_name"
                            customPlaceholderKey="app.common.form.input.bank_name.placeholder.alternative"
                            isClearable={false}
                            isLabelDisplayed={false}
                            value={values?.bank_name}
                            isDisabled={isDisabled}
                        />
                        <FormAutoSubmit />
                    </Flex>
                )
            }}
        </Form>
    )
}
