import {
    filterUserAccessQueryParams,
    PaymentOrderReturns,
    PERMISSION,
    WIDGET_TYPE,
    WidgetsServicePaymentOrderReturnsQueryOptions
} from '@/services'
import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query'
import { useAuth, usePermissions, useServices } from '@/hooks'
import { APIError } from '@/types'

export function useQueryPaymentOrderReturns(
    params: WidgetsServicePaymentOrderReturnsQueryOptions,
    options?: Partial<UndefinedInitialDataOptions<PaymentOrderReturns, APIError>>
) {
    const { widgetsService } = useServices()
    const { isUserLoggedIn, selectedUserAccess } = useAuth()
    const { hasPermission } = usePermissions()
    const isQueryEnabled = isUserLoggedIn && hasPermission(PERMISSION.HOME_VIEW_RETURNS) && options?.enabled

    const paramsWithUserAccess: typeof params = {
        ...params,
        ...filterUserAccessQueryParams(selectedUserAccess)
    }

    return useQuery<PaymentOrderReturns, APIError>({
        ...options,
        enabled: isQueryEnabled,
        queryKey: [widgetsService.url, paramsWithUserAccess, WIDGET_TYPE.PaymentOrderReturns],
        queryFn({ signal }) {
            return widgetsService.paymentOrderReturns(paramsWithUserAccess, { signal })
        }
    })
}
