import { ErrorBoundary, IncompleteUserInfoModal } from '@/components'
import { If } from '@/components/@misc'
import { useDeviceDetector, useLocaleSwitcher } from '@/hooks'
import { UnsupportedDevicePage } from '@/pages'
import {
    AuthProvider,
    ConfigurationProvider,
    CustomQueryClientProvider,
    NavigationRoutesProvider,
    PageProvider,
    ServicesProvider,
    ToastsProvider
} from '@/providers'
import { CUSTOM_CHAKRA_THEME } from '@/theme'
import { ChakraProvider } from '@chakra-ui/react'
import { noop } from 'lodash'
import { IntlProvider } from 'react-intl'
import { BrowserRouter as Router } from 'react-router-dom'
import { AnalyticsHandler } from './AnalyticsHandler'
import { AppRoutes } from './AppRoutes'
import { ScrollToTop } from './ScrollToTop'

export const AppWithProviders: React.FC = () => {
    const { isMobileBrowser } = useDeviceDetector()
    const { locale, messages } = useLocaleSwitcher()

    return (
        <IntlProvider locale={locale} messages={messages} onError={noop}>
            <ChakraProvider theme={CUSTOM_CHAKRA_THEME}>
                <If condition={isMobileBrowser}>
                    <UnsupportedDevicePage />
                </If>
                <If condition={!isMobileBrowser}>
                    <Router>
                        <ErrorBoundary>
                            <ScrollToTop />
                            <ConfigurationProvider>
                                <CustomQueryClientProvider>
                                    <NavigationRoutesProvider>
                                        <ServicesProvider>
                                            <AuthProvider>
                                                <ToastsProvider>
                                                    <AnalyticsHandler />
                                                    <PageProvider>
                                                        <AppRoutes />
                                                        <IncompleteUserInfoModal />
                                                    </PageProvider>
                                                </ToastsProvider>
                                            </AuthProvider>
                                        </ServicesProvider>
                                    </NavigationRoutesProvider>
                                </CustomQueryClientProvider>
                            </ConfigurationProvider>
                        </ErrorBoundary>
                    </Router>
                </If>
            </ChakraProvider>
        </IntlProvider>
    )
}
