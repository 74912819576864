import { QueryParamStateFilterBy, useQuickFilter } from '@/hooks'
import { Box, Button, Flex } from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import { chain, invoke } from 'lodash'
import React, { cloneElement, useCallback, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { TABLE_HEADER_FILTER_INPUT_WIDTH } from '../../TableHeader.const'
import { TableHeaderPanel, TableHeaderPanelHeader } from '../TableHeaderPanel'
import { TableHeaderFilterByProps } from './TableHeaderFilterBy.types'
import {
    countActiveFiltersValues,
    getFilterByInitialValues,
    isFilterByInputDisabled,
    removeReservedWordsFilter
} from './TableHeaderFilterBy.utils'

export function TableHeaderFilterBy<T = void>({ filters, value }: TableHeaderFilterByProps<T>) {
    const intl = useIntl()
    const quickFilter = useQuickFilter<T>()
    const { values, resetForm } = useFormikContext<QueryParamStateFilterBy<T>>()
    const onReset = useCallback(() => {
        invoke(quickFilter, 'onReset')

        resetForm({
            values: {
                ...values,
                ...getFilterByInitialValues<QueryParamStateFilterBy<T>>(filters)
            }
        })
    }, [resetForm, values, filters, quickFilter])
    const activeFilterValues = useMemo(() => countActiveFiltersValues<T>(value), [value])
    const panelTitle = useMemo(() => {
        return intl.formatMessage({ id: 'app.table.header.filters.title' })
    }, [intl])
    const filterInputs = useMemo(() => {
        return chain(filters)
            .entries()
            .filter(([name]) => removeReservedWordsFilter(name))
            .map(([name, FilterInput]: [string, React.ReactElement]) => {
                const isDisabled = isFilterByInputDisabled<T>(name, quickFilter.quickFilters, quickFilter.state, values)

                return (
                    <Box width={`${TABLE_HEADER_FILTER_INPUT_WIDTH}px`} key={name}>
                        {isDisabled ? cloneElement(FilterInput, { isDisabled }) : FilterInput}
                    </Box>
                )
            })
            .value()
    }, [quickFilter, filters, values])

    return (
        <TableHeaderPanel className="TableHeaderFilters">
            <TableHeaderPanelHeader title={panelTitle}>
                <Button
                    variant="ghost"
                    colorScheme="blue"
                    color="numeralAccent.500"
                    onClick={onReset}
                    isDisabled={!activeFilterValues}>
                    <FormattedMessage id="app.table.header.filters.actions.clear.label" />
                </Button>
            </TableHeaderPanelHeader>
            <Flex width="100%" flexWrap="wrap" gap="16px">
                {filterInputs}
            </Flex>
        </TableHeaderPanel>
    )
}
