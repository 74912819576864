import { getFormSelectPlaceholderByReactQueryState } from '@/components'
import { Select, useSearchQuerySelect } from '@/components/@misc'
import { usePermissions, useQueryFindAllExternalAccounts } from '@/hooks'
import { PERMISSION } from '@/services'
import { FormikInput } from '@/types'
import { queryDataAggregation } from '@/utils'
import { uuidValidator } from '@/utils/@validators'
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import { ExternalAccount, ExternalAccountsServiceFindAllQueryOptions } from '@webapps/numeral-ui-core'
import { useField } from 'formik'
import { identity } from 'lodash'
import { useCallback, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { OptionProps } from 'react-select/dist/declarations/src/components/Option'
import { TextInput } from '../TextInput'
import { ExternalAccountInputSelectOption } from './@components'
import { getExternalAccountsOptionLabel } from './ExternalAccountsInput.utils'

export interface ExternalAccountsInputProps<T = ExternalAccount> extends FormikInput {
    isAccountVerificationStatusDisplayed?: boolean
    queryParams?: Partial<ExternalAccountsServiceFindAllQueryOptions>
    queryDataFilter?: (value: T, index: number, array: T[]) => unknown
}

export const ExternalAccountsInput: React.FC<ExternalAccountsInputProps> = ({
    name,
    value,
    isDisabled,
    customPlaceholderKey,
    isAccountVerificationStatusDisplayed = false,
    isPlaceholderDisplayed = false,
    validator = uuidValidator,
    isRequired,
    queryParams,
    queryDataFilter = identity,
    ...inputProps
}) => {
    const intl = useIntl()
    const { hasPermission } = usePermissions()
    const [field, meta, helpers] = useField({
        name,
        value,
        validate: validator(intl, isRequired)
    })
    const { enabled, search, limit, inputText, onInputChange, onFocus } = useSearchQuerySelect()
    const query = useQueryFindAllExternalAccounts(
        { search, limit, ...queryParams },
        {
            enabled: !isDisabled && enabled
        }
    )
    const data = useMemo<ExternalAccount[]>(() => {
        return queryDataAggregation(query.data).filter(queryDataFilter)
    }, [query, queryDataFilter])
    const placeholder = useMemo<string | undefined>(() => {
        if (customPlaceholderKey) {
            return intl.formatMessage({ id: customPlaceholderKey })
        }
        return getFormSelectPlaceholderByReactQueryState(intl, query, isPlaceholderDisplayed)
    }, [customPlaceholderKey, intl, query, isPlaceholderDisplayed])
    const onChange = useCallback(
        (value: string) => {
            inputProps.onChange?.(value)
            helpers.setValue(value)
        },
        [helpers, inputProps]
    )
    const SelectOptionComponent = useMemo(() => {
        return function ExternalAccountsInputSelectOptionsWithProps(props: OptionProps<any>) {
            return (
                <ExternalAccountInputSelectOption
                    {...props}
                    isAccountVerificationStatusDisplayed={isAccountVerificationStatusDisplayed}
                />
            )
        }
    }, [isAccountVerificationStatusDisplayed])

    if (hasPermission(PERMISSION.COUNTERPARTIES_VIEW_EXTERNAL_ACCOUNTS)) {
        const isInvalid = meta.touched && !!meta.error

        return (
            <FormControl key={name} isInvalid={isInvalid}>
                <FormLabel htmlFor={name}>
                    <FormattedMessage id="app.common.form.input.external_account.label" />
                </FormLabel>
                <Select<ExternalAccount>
                    {...inputProps}
                    {...field}
                    id={name}
                    options={data}
                    placeholder={placeholder}
                    isInvalid={isInvalid}
                    isRequired={isRequired}
                    isDisabled={isDisabled}
                    isLoading={query.isLoading}
                    isClearable={true}
                    inputValue={inputText}
                    onChange={onChange}
                    onFocus={onFocus}
                    onInputChange={onInputChange}
                    getOptionLabel={getExternalAccountsOptionLabel}
                    getOptionValue={(option) => option.id}
                    components={{
                        Option: SelectOptionComponent
                    }}
                    noOptionsMessage={() =>
                        intl.formatMessage({
                            id: 'app.common.form.input.external_account.no_result_found.title'
                        })
                    }
                />
                <FormErrorMessage>{meta.error}</FormErrorMessage>
            </FormControl>
        )
    }

    return <TextInput {...field} {...inputProps} id={name} placeholder={placeholder} validator={validator} />
}
