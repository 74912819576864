import {
    QueryFallback,
    RestrictedSection,
    Table,
    TableHeader,
    TableHeaderActionProps,
    useTableRowNavigation
} from '@/components'
import { useNavigationRoutes, usePermissions, useQueryFindAllDirectDebitMandates, useQueryParams } from '@/hooks'
import { PageHeaderLayout, PageLayout } from '@/layouts'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { queryDataAggregation } from '@/utils'
import {
    ApiObjectTypeSchema,
    DirectDebitMandate,
    DirectDebitMandatesServiceFindAllQueryOptions,
    ExternalAccountsServiceFindAllQueryOptions
} from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import {
    DIRECT_DEBIT_MANDATE_DOCUMENTATION_LINK,
    DIRECT_DEBIT_MANDATE_FILTERS,
    DIRECT_DEBIT_MANDATE_TABLE_COLUMN_CUSTOMIZERS,
    DIRECT_DEBIT_MANDATE_TABLE_STATIC_STATE
} from './DirectDebitMandates.page.const'

export const DirectDebitMandatesPage: React.FC = () => {
    const intl = useIntl()
    const { hasPermission } = usePermissions()
    const navigate = useNavigate()
    const { relativePaths } = useNavigationRoutes()
    const title = useMemo<string>(
        () => intl.formatMessage({ id: 'app.counterparties.direct_debit_mandates.title' }),
        [intl]
    )
    const { search, filterBy } = useQueryParams<ExternalAccountsServiceFindAllQueryOptions>('search', {
        filterBy: { configuration: { filters: DIRECT_DEBIT_MANDATE_FILTERS } }
    })
    const query = useQueryFindAllDirectDebitMandates({ ...search.parsedState, ...filterBy.parsedState })
    const data = useMemo(() => queryDataAggregation(query.data), [query])
    const isDataEmpty = useMemo<boolean>(() => isEmpty(data), [data])

    const onTableRowClick = useTableRowNavigation<DirectDebitMandate>('id', relativePaths.COUNTERPARTIES.DETAILS)
    const actions = useMemo<TableHeaderActionProps[]>(() => {
        const actions: TableHeaderActionProps[] = []

        if (hasPermission(PERMISSION.COUNTERPARTIES_CREATE_DIRECT_DEBIT_MANDATES)) {
            actions.push({
                onClick() {
                    navigate(relativePaths.NEW)
                },
                children: intl.formatMessage({
                    id: 'app.counterparties.direct_debit_mandates.create.form.actions.submit.label'
                })
            })
        }

        return actions
    }, [hasPermission, intl, isDataEmpty, navigate, relativePaths.NEW, search.parsedState, filterBy.parsedState])

    return (
        <PageLayout>
            <PageHeaderLayout title={title} showBackButton={false} />
            <RestrictedSection
                feature={ENTITY_FEATURE.DIRECT_DEBIT_MANDATE}
                permission={PERMISSION.COUNTERPARTIES_VIEW_DIRECT_DEBIT_MANDATES}>
                <TableHeader<DirectDebitMandatesServiceFindAllQueryOptions>
                    objectType={ApiObjectTypeExtendedSchema.enum.direct_debit_mandate}
                    isLoading={query.isLoading}
                    actions={actions}
                    filterBy={filterBy}
                    search={search}
                />
                <QueryFallback
                    objectType={ApiObjectTypeSchema.enum.direct_debit_mandate}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError}
                    isDataEmpty={isDataEmpty}
                    isFilteringOrSearching={search.isActive || filterBy.isActive}
                    learnMoreLinkUrl={DIRECT_DEBIT_MANDATE_DOCUMENTATION_LINK}>
                    <Table<DirectDebitMandate>
                        data={data}
                        onRowClick={onTableRowClick}
                        onScrollToBottom={query.fetchNextPage}
                        isLoading={query.hasNextPage}
                        state={DIRECT_DEBIT_MANDATE_TABLE_STATIC_STATE}
                        columnCustomizers={DIRECT_DEBIT_MANDATE_TABLE_COLUMN_CUSTOMIZERS}
                    />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
