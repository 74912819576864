import {
    EntityDetailCellTextWithCopyButton,
    EntityDetailRow,
    EntityDetailRowCustomizers,
    TableCellAccount
} from '@/components'
import {
    AmountRange,
    ConnectedAccountName,
    DateRange,
    Direction,
    FormattedAmount,
    FormattedDateTime,
    ReconciliationStatusLabel
} from '@/components/@misc'
import { accountNumberFormatter, whitespaceFormatter } from '@/utils/@formatters'
import { ExpectedPayment } from '@webapps/numeral-ui-core'
import { ExpectedPaymentDescriptions } from './@components'
import { ExpectedPaymentCustomRows } from './ExpectedPaymentDetails.types'

export const EXPECTED_PAYMENT_DETAILS_ROWS: EntityDetailRow<ExpectedPayment, ExpectedPaymentCustomRows>[] = [
    'id',
    'created_at',
    'direction',
    'amount_interval',
    'date_interval',
    'connected_account_id',
    'external_account',
    'internal_account',
    'descriptions',
    'reconciliation_status',
    'reconciled_amount',
    'canceled_at',
    'metadata',
    'idempotency_key'
]

export const EXPECTED_PAYMENT_DETAILS_ROW_CUSTOMIZERS: EntityDetailRowCustomizers<
    ExpectedPayment,
    ExpectedPaymentCustomRows
> = {
    id: {
        cell(item) {
            return <EntityDetailCellTextWithCopyButton value={item.id} />
        }
    },
    created_at: {
        cell(item) {
            return <FormattedDateTime value={item.created_at} />
        }
    },
    direction: {
        cell(item) {
            return <Direction value={item.direction} />
        }
    },
    amount_interval: {
        cell(item) {
            return <AmountRange from={item.amount_from} to={item.amount_to} currency={item.currency} />
        }
    },
    date_interval: {
        cell(item) {
            return <DateRange from={item.start_date} to={item.end_date} />
        }
    },
    connected_account_id: {
        cell(item) {
            return <ConnectedAccountName connectedAccountId={item.connected_account_id} />
        }
    },
    external_account: {
        cell(item) {
            return <TableCellAccount value={item.external_account} />
        }
    },
    internal_account: {
        cell(item) {
            const formattedValue = accountNumberFormatter(item.internal_account?.account_number)
            return <EntityDetailCellTextWithCopyButton value={formattedValue} formatter={whitespaceFormatter} />
        }
    },
    reconciliation_status: {
        cell(item) {
            return <ReconciliationStatusLabel value={item.reconciliation_status} />
        }
    },
    reconciled_amount: {
        cell(item) {
            return <FormattedAmount amount={item.reconciled_amount} currency={item.currency} />
        }
    },
    canceled_at: {
        cell(item) {
            return <FormattedDateTime value={item.canceled_at} />
        }
    },
    descriptions: {
        cell(item) {
            return <ExpectedPaymentDescriptions value={item.descriptions} />
        }
    },
    metadata: {
        nested: true
    }
}
