import {
    EntityDetailCellRelatedObjectsLinks,
    EntityDetailCellTextWithCopyButton,
    EntityDetailRow,
    EntityDetailRowCustomizers,
    TableCellAccount,
    TableCellShortDate
} from '@/components'
import {
    ConnectedAccountName,
    Direction,
    FormattedAmount,
    FormattedDateTime,
    ReconciliationStatusLabel,
    TransactionCategory
} from '@/components/@misc'
import { Transaction } from '@webapps/numeral-ui-core'
import { TransactionDetailsCustomRows } from './TransactionDetails.types'

export const TRANSACTION_DETAILS_ROWS: EntityDetailRow<Transaction, TransactionDetailsCustomRows>[] = [
    'id',
    'created_at',
    'direction',
    'amount',
    'connected_account_id',
    'external_account',
    'internal_account',
    'category',
    'description',
    'value_date',
    'booking_date',
    'reconciliation_status',
    'reconciled_amount',
    'related_objects',
    'custom_fields',
    'bank_data'
]

export const TRANSACTION_DETAILS_ROW_CUSTOMIZERS: EntityDetailRowCustomizers<
    Transaction,
    TransactionDetailsCustomRows
> = {
    id: {
        cell(item) {
            return <EntityDetailCellTextWithCopyButton value={item.id} />
        }
    },
    created_at: {
        cell(item) {
            return <FormattedDateTime value={item.created_at} />
        }
    },
    direction: {
        cell(item) {
            return <Direction value={item.direction} />
        }
    },
    amount: {
        cell(item) {
            return <FormattedAmount amount={item.amount} currency={item.currency} />
        }
    },
    connected_account_id: {
        cell(item) {
            return <ConnectedAccountName connectedAccountId={item.connected_account_id} />
        }
    },
    external_account: {
        cell(item) {
            return <TableCellAccount value={item.external_account} />
        }
    },
    internal_account: {
        cell(item) {
            return <TableCellAccount value={item.internal_account} />
        }
    },
    category: {
        cell(item) {
            return <TransactionCategory value={item.category} />
        }
    },
    value_date: {
        cell(item) {
            return <TableCellShortDate value={item.value_date} />
        }
    },
    booking_date: {
        cell(item) {
            return <TableCellShortDate value={item.booking_date} />
        }
    },
    reconciliation_status: {
        cell(item) {
            return <ReconciliationStatusLabel value={item.reconciliation_status} />
        }
    },
    reconciled_amount: {
        cell(item) {
            return <FormattedAmount amount={item.reconciled_amount} currency={item.currency} />
        }
    },
    related_objects: {
        cell(item) {
            return (
                <EntityDetailCellRelatedObjectsLinks
                    relatedInternalAccountId={item.internal_account_id}
                    relatedExternalAccountId={item.external_account_id}
                    relatedFileId={item.bank_data?.file_id}
                />
            )
        }
    },
    bank_data: {
        nested: true
    }
}
