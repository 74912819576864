import { useAuth, usePermissions, useServices } from '@/hooks'
import { filterUserAccessQueryParams, PERMISSION } from '@/services'
import { type UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query'
import { DirectDebitMandate, Uuid } from '@webapps/numeral-ui-core'

export function useQueryFindByIdDirectDebitMandate(
    id?: Uuid,
    options?: Partial<UndefinedInitialDataOptions<DirectDebitMandate, Error>>
) {
    const { directDebitMandatesService } = useServices()
    const { isUserLoggedIn, selectedUserAccess } = useAuth()
    const { hasPermission } = usePermissions()

    const isQueryEnabled =
        isUserLoggedIn && hasPermission(PERMISSION.COUNTERPARTIES_VIEW_DIRECT_DEBIT_MANDATES) && options?.enabled
    const paramsWithUserAccess = {
        ...filterUserAccessQueryParams(selectedUserAccess)
    }

    return useQuery<DirectDebitMandate, Error>({
        ...options,
        enabled: isQueryEnabled,
        queryKey: [directDebitMandatesService.url, { id, ...paramsWithUserAccess }],
        queryFn({ signal }) {
            return directDebitMandatesService.findById(id as string, { signal, params: paramsWithUserAccess })
        }
    })
}
