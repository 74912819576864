import {
    FilterInputs,
    TableCellAccount,
    TableCellShortDate,
    TableColumnCustomizers,
    TableStateWithEntity
} from '@/components'
import {
    ConnectedAccountsInput,
    DateInput,
    DirectDebitMandateDirectionInput,
    DirectDebitMandateSequenceInput,
    DirectDebitMandateStatusInput,
    DirectDebitMandateTypeInput,
    TextInput
} from '@/components/@inputs'
import { FormattedTextIntl, StatusLabel } from '@/components/@misc'
import { COLUMN_WIDTH_RATIO, DOCUMENTATION_BASE_URL, MIN_COLUMN_WIDTH } from '@/constants'
import { uuidValidator } from '@/utils/@validators'
import { DirectDebitMandate, DirectDebitMandatesServiceFindAllQueryOptions } from '@webapps/numeral-ui-core'

export const DIRECT_DEBIT_MANDATE_DOCUMENTATION_LINK = `${DOCUMENTATION_BASE_URL}/direct-debit-mandate-object`

export const DIRECT_DEBIT_MANDATE_TABLE_STATIC_STATE: TableStateWithEntity<DirectDebitMandate> = {
    columnOrder: Object.freeze([
        'created_at',
        'originating_account',
        'receiving_account',
        'type',
        'sequence',
        'reference',
        'status'
    ])
}

export const DIRECT_DEBIT_MANDATE_TABLE_COLUMN_CUSTOMIZERS: TableColumnCustomizers<DirectDebitMandate> = {
    created_at: {
        minSize: MIN_COLUMN_WIDTH.SHORT_DATE,
        size: COLUMN_WIDTH_RATIO.SHORT_DATE,
        cell(info) {
            return <TableCellShortDate value={info.getValue()} />
        }
    },
    originating_account: {
        cell(info) {
            return <TableCellAccount value={info.getValue()} />
        }
    },
    receiving_account: {
        cell(info) {
            return <TableCellAccount value={info.getValue()} />
        }
    },
    type: {
        minSize: MIN_COLUMN_WIDTH.DIRECT_DEBIT_TYPE,
        cell(info) {
            const type = info.getValue()
            return <FormattedTextIntl messageId={`api.direct_debit_mandate.type.${type}`} defaultMessage={type} />
        }
    },
    sequence: {
        cell(info) {
            const sequence = info.getValue()
            return (
                <FormattedTextIntl
                    messageId={`api.direct_debit_mandate.sequence.${sequence}`}
                    defaultMessage={sequence}
                />
            )
        }
    },
    status: {
        minSize: MIN_COLUMN_WIDTH.STATUS,
        cell(info) {
            return <StatusLabel value={info.getValue()} />
        }
    }
}

export const DIRECT_DEBIT_MANDATE_FILTERS: FilterInputs<DirectDebitMandatesServiceFindAllQueryOptions> = {
    start_date: <DateInput name="start_date" />,
    end_date: <DateInput name="end_date" />,
    start_signature_date: <DateInput name="start_signature_date" />,
    end_signature_date: <DateInput name="end_signature_date" />,
    receiving_account_id: <TextInput name="receiving_account_id" validator={uuidValidator} />,
    connected_account_id: <ConnectedAccountsInput name="connected_account_id" isPlaceholderDisplayed={true} />,
    type: <DirectDebitMandateTypeInput name="type" isPlaceholderDisplayed={true} />,
    sequence: <DirectDebitMandateSequenceInput name="sequence" isPlaceholderDisplayed={true} />,
    status: <DirectDebitMandateStatusInput name="status" isPlaceholderDisplayed={true} />,
    direction: <DirectDebitMandateDirectionInput name="direction" isPlaceholderDisplayed={true} />,
    reference: <TextInput name="reference" />
}
